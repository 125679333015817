import {Fragment} from "react";
import ChooseButton from "./ChooseButton";
import {getButtonIcon} from "../utilities/icons";
import {useTranslation} from "react-i18next";

export default function PlatformList({platforms, setSelectedPlatform}) {
    const [t] = useTranslation('common');
    return(
        <Fragment>
            <h2 style={{textAlign: "center", margin: "2rem"}}>{t('appointment.choosePlatformTypes')}</h2>
            <div style={{padding: "0 2rem 2rem 2rem"}}>

                {
                    platforms.map((el, i) => {
                        return (
                            <ChooseButton
                                key={i}
                                title={el.title}
                                content={el.content}
                                onClick={() => setSelectedPlatform(el)}
                                icon={getButtonIcon(el.code)}
                                isEnabled={true}
                            />
                        )
                    })
                }
            </div>
        </Fragment>
    )
}

PlatformList.defaultProps = {
    platforms: [],
    setSelectedPlatform: null
}