import './App.scss';
import {Route, Routes} from "react-router-dom";
import DesirePage from "./pages/DesirePage";
import AppointmentPage from "./pages/AppointmentPage";
import InfoPage from "./pages/InfoPage";
import NotFound from "./pages/NotFound";
import LocalePage from "./pages/LocalePage";
import {useEffect, useState} from "react";
import GenericPage from "./pages/GenericPage";
import {isChattingEnabled} from "./utilities/checkers";
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";
import Loading from "./components/Loading";
import {useLocation} from "react-router-dom";
import Wrapper from "./components/Wrapper";
import {loadStaticData} from "./utilities/getters";

function App() {
    const [, i18n] = useTranslation('common');
    const [cookies, setCookie] = useCookies(['lang']);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const [staticData, setStaticData] = useState();

    useEffect(() => {

        if (isChattingEnabled()) appendUserLike();

        function appendUserLike() {
            const script = document.createElement("script");
            script.id = 'userlike-chat'
            script.src = `https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/${process.env.REACT_APP_USERLIKE_WIDGET_KEY}.js`;
            script.async = true;

            document.body.appendChild(script)
        }

        const setDefaultLang = async () => {
            if (cookies.lang && (cookies.lang.short !== i18n.language)) {
                await i18n.changeLanguage(cookies.lang.short);
            } else { // No Cookie set, set German as default
                await setCookie('lang', {
                    short: 'de',
                    long: 'de_AT'
                }, { path: '/' });
            }

            if(!cookies.lang) return 'de_AT';
            return cookies.lang.long;
        }

        setDefaultLang().then((langLong) => {
            loadStaticDataAndSet(langLong).then(() => {
                setIsLoading(false)
            });
        });
    // eslint-disable-next-line
    }, [isLoading, i18n, setCookie]);

    const loadStaticDataAndSet = async (langLong) => {
        await loadStaticData(langLong).then((result) => {
            setStaticData({
                footerData: result.footerText,
                siteTitle: result.title
            });
        });
    }

    if(isLoading) return <Loading withoutWrapper={location.pathname === '/'}/>;

    return(
        <Wrapper hidden={location.pathname === '/'} staticData={staticData} >
            <Routes>
                <Route path="/" element={<LocalePage reloadStatic={loadStaticDataAndSet} />} />
                <Route path="/desire" element={<DesirePage />} />
                <Route path="/appointment" element={<AppointmentPage />} />
                <Route path="/infos" element={<InfoPage />} />
                <Route path="/imprint" element={<GenericPage type="imprint" />} />
                <Route path="/dataprivacy" element={<GenericPage type="dataprivacy" />} />
                <Route path="*" element={<NotFound />}/>
            </Routes>
        </Wrapper>
    )
}

export default App;
