import {useTranslation} from "react-i18next";
import {Fragment, useEffect, useState} from "react";
import Loading from "../components/Loading";
import ErrorMessage from "../components/ErrorMessage";
import Element from "../components/Element";
import { useLocation } from 'react-router-dom'
import {useCookies} from "react-cookie";

export default function InfoPage() {
    const [t] = useTranslation('common');
    const [cookies] = useCookies(['lang']);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [isLoading, setIsLoading] = useState(true);
    const [pages, setPages] = useState([]);
    const [pageContent, setPageContent] = useState();
    const [showError, setShowError] = useState(false);
    const [flowState, setFlowState] = useState(0); // 0 Selecting Mode | 1 Detail Mode

    // Get Data for PREVIEW
    const isPreview = !!searchParams.get('isPreview');
    const pageId = searchParams.get('pageId');
    const [previewLoaded, setPreviewLoaded] = useState(false);

    async function closeErrorMessage() {
        setShowError(false);
        setIsLoading(true);
        await loadPages();
    }

    if(isPreview && !previewLoaded) {
        if(pageId !== null) {
            loadData(pageId).then((r) =>  setPreviewLoaded(true));
        }
    }

    const loadPages = async () => {
        await fetch(
            process.env.REACT_APP_API_HOST + '/api/static/' + cookies.lang.long, {
                method: 'GET'
            }
        ).then(response => {
            return response.json();
        }).then(data => {
            setPages(data);
            setIsLoading(false);
        }).catch((e) => {
            setShowError(true);
            setIsLoading(false);
        });
    }

    async function loadData(pageId) {
        await setIsLoading(true);
        await setFlowState(1);

        await fetch(
            process.env.REACT_APP_API_HOST + '/api/static/' + cookies.lang.long + '/' + isPreview.toString() + '/getContent/' + pageId, {
                method: 'GET'
            }
        ).then(async response => {
            let data = await response.json();
            await setPageContent(data);
            await setIsLoading(false);
        }).catch((e) => {
            setShowError(true);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        loadPages().then(r => {
            return r
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if(isLoading) return <Loading />
    if(showError) return <ErrorMessage
        title={t('error.title')}
        message={t('error.content')}
        onClose={() => closeErrorMessage()}
    />

    if(flowState === 0) {
        return (
            <Fragment>
                <header className="titlerow-header">
                    <figure>
                        <img className="avatar" src="/img/Sophie.svg" alt="Sophie" />
                    </figure>
                    <h1>{t('info.title')}</h1>
                </header>
                <section className="section-basic section-basic__w-md">
                    <div className="infopagelist">
                        {
                            pages.map((page, i) => {
                                return <button className={`btn btn--lg ${page.title}`} onClick={() => loadData(page.id)} key={i}><span className="btn__head">{page.title}</span></button>
                            })
                        }
                    </div>
                </section>
            </Fragment>
        )
    }

    if(flowState === 1) {
        return (
            <article className="infopage">
                {
                    pageContent ?
                        <Fragment>

                            {
                                pageContent.elements ?
                                    pageContent.elements.map((element, i) => {
                                        return <Element key={i} element={element} loadData={(pageId) => loadData(pageId)} />
                                    }) : null
                            }
                            {
                                pageContent.content &&
                                    <div dangerouslySetInnerHTML={{__html: pageContent.content}} />
                            }

                        </Fragment> :
                        <Fragment>
                            <div style={{marginTop: '2rem'}}>
                                Etwas ging schief, keine Daten zu dieser Seite vorhanden
                            </div>

                        </Fragment>
                }
                <div className="a-c">
                <button className="btn btn--ac" onClick={() => setFlowState(0)}>Zurück zur Übersicht</button>
                </div>
            </article>
        )
    }
}

InfoPage.defaultProps = {}