import ChooseButton from "../components/ChooseButton";
import {useTranslation} from "react-i18next";
import * as Icon from 'react-feather';
import TitleRow from "../components/TitleRow";
import {useNavigate} from "react-router-dom";
import {Fragment, useEffect} from "react";

export default function DesirePage({loadedStatic, setLoadedStatic}) {
    const navigate = useNavigate();
    const [t] = useTranslation('common');


    useEffect(() => {
        if(loadedStatic) setLoadedStatic(false);
    }, []);

    function goStartAppointmentBooking()
    {
        navigate("/appointment");
    }

    function goToInfoIndexPage()
    {
        navigate("/infos");
    }

    return (
        <Fragment>
            <TitleRow title={t('desire.title')} />

            <section className="section-basic section-basic__w-md">
                <ChooseButton
                    onClick={goStartAppointmentBooking}
                    title={t('desire.bookingTitle')}
                    content={t('desire.bookingContent')}
                    icon={<Icon.Calendar size={48} color="#FFF" />}
                    bgcolor="#F68C01"
                />
                <ChooseButton
                    onClick={goToInfoIndexPage}
                    title={t('desire.videoTitle')}
                    content={t('desire.videoContent')}
                    bgcolor="#17A2B8"
                    icon={<Icon.Info size={48} color="#FFF" />}
                    isEnabled={true}
                />
            </section>
        </Fragment>
    )
}
