import {useNavigate} from "react-router-dom";
import {registerLocale} from "react-datepicker";
import de from "date-fns/locale/de";
import {useTranslation} from "react-i18next";
import Flag from "react-flagkit";
import { useCookies } from 'react-cookie';


export default function LocaleButton({locale, reloadStatic}) {
    const navigate = useNavigate();
    const [, setCookie] = useCookies(['lang']);
    const [, i18n] = useTranslation('common');

    async function selectAndGoToDesirePage() {
        let localeShort = locale.locale.substr(0, 2)
        registerLocale('de', de); // We register German anyway because its an german webapp
        // ToDo: Register all Locales that are available
        // if(localeShort === 'de') registerLocale('de', de);

        setCookie('lang', {
            short: localeShort,
            long: locale.locale
        }, { path: '/' })

        await i18n.changeLanguage(localeShort);
        await reloadStatic(locale.locale);
        navigate("/desire");
    }

    return(
        <button onClick={selectAndGoToDesirePage} className="btn--locale">
            <span><Flag country={locale.locale.substr(3, 2)} size={50} /></span>
            {locale.title}
        </button>
    )
}

LocaleButton.defaultProps = {
    locale: null
};