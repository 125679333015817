import {Edit3} from "react-feather";

export default function BookingOverviewItem({title, onEdit, children, spaceTop}) {

    function getStyles() {
        if(spaceTop) return {marginTop: "1rem"};
        return null;
    }

    return (
        <div style={getStyles()}>
            <div style={{fontWeight: "bold"}}>{title}:</div>
            <div className="col-2">
                {children}
                <Edit3 className="center-vertical" style={{cursor: "pointer", marginLeft: "auto"}} onClick={onEdit} color="#a5002d" />
            </div>
        </div>
    )
}

BookingOverviewItem.defaultProps = {
    title: '',
    onEdit: null,
    spaceTop: false
}