import {useTranslation} from "react-i18next";
import {Fragment, useState} from "react";
import ErrorMessage from "./ErrorMessage";
import {ChevronsRight} from "react-feather";
import {validateEmail, validatePhone, validatePhonePrefix} from "../utilities/checkers";
import GenericPageContent from "./GenericPageContent";

export default function ClientDataEntryForm({name, setName, email, setEmail, phone, setPhone, prefix, setPrefix, topic, setTopic, selectedAppointmentType, onSuccess}) {
    const [t] = useTranslation('common');

    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [showPrivacy, setShowPrivacy] = useState(false);
    const [dsgvo, setDsgvo] = useState(false);

    async function validateAndGoToOverView() {
        let valid = true;
        await setErrorMessage("");

        if(name.length < 3) {
            valid = false;
            await setErrorMessage(t('appointment.nameErrorMsg'));
        } else if(email === '' && phone === '') {
            valid = false;
            await setErrorMessage(t('appointment.phoneormailErrorMsg'));
        } else if (email !== '' ) {
            if(!validateEmail(email)) {
                valid = false;
                await setErrorMessage(t('appointment.emailErrorMsg'));
            }
        } else if (email === '') {
            if(phone === '' || !validatePhone(phone) || !validatePhonePrefix(prefix)) {
                valid = false;
                await setErrorMessage(t('appointment.phoneErrorMsg'));
            }
        }

        if (valid && !dsgvo) {
            valid = false;
            await setErrorMessage(t('booking.ErrorPrivacyData'));
        }

        if(valid) {
            await setShowError(false);
            await setErrorMessage("");
            onSuccess();
        } else {
            await setShowError(true);
        }
    }

    function closeErrorMessage() {
        setShowError(false);
        setErrorMessage("");
    }

    if(showError) return <ErrorMessage title="Ups, da ging was schief!" message={errorMessage} onClose={() => closeErrorMessage()} />
    if(showPrivacy) {
        return (
            <Fragment>
                <section className="section-basic section-basic__w-md">
                    <div className="btn" style={{cursor: "pointer", marginBottom:0, marginLeft:0}} onClick={() => setShowPrivacy(false)}>{t('general.close')}</div>
                    <GenericPageContent
                        isPreview={false}
                        type="dataprivacy"
                    />
                </section>
            </Fragment>
        )
    }

    return (
        <section className="section-basic section-basic__w-md">
            <h2 style={{textAlign: "center"}}>{t('appointment.giveInformationHeading')}</h2>

            <div className="form form__client-data client-data-entry-form">

                <p>
                    {t('appointment.giveInformationDesc')}
                </p>

                <div style={{marginTop: "20px"}}>
                    <label htmlFor="name">{t('general.name')}</label><br />
                    <input type="text" id="name"
                           onChange={(e) => {setName(e.target.value)}}
                           value={name}
                    />
                    <span className="helper-text">{t('appointment.nameHelpMsg')}</span>

                </div>

                {
                    selectedAppointmentType.code !== 'phone' &&
                    <div style={{marginTop: "20px"}}>
                        <label htmlFor="email">{t('general.email')}</label><br />
                        <input type="email" id="email"
                               onChange={(e) => {setEmail(e.target.value)}}
                               value={email}
                        />
                    </div>
                }

                <div style={{marginTop: "20px"}}>
                    <label htmlFor="tel">{t('general.phone')}</label><br />
                    <div style={{display: "flex", gap: "0.5rem"}}>
                        <input type="prefix" id="prefix" style={{width: "5rem"}}
                               onChange={(e) => {setPrefix(e.target.value)}}
                               value={prefix}
                        />
                        <input type="phone" id="phone"
                               onChange={(e) => {setPhone(e.target.value)}}
                               value={phone}
                        />
                    </div>
                    <span className="helper-text">{t('appointment.phoneHelpMsg')}</span>
                </div>
                <div style={{marginTop: "20px"}}>
                    <label htmlFor="topic">{t('general.topic')}<span className="optional-info">({t('general.optional')})</span></label><br />
                    <div style={{display: "flex", gap: "0.5rem"}}>
                        <textarea id="topic"
                               onChange={(e) => {setTopic(e.target.value)}}
                               value={topic}
                        />
                    </div>
                    <span className="helper-text">{t('appointment.topicHelpMsg')}</span>
                </div>
                <div className="form-checkbox" style={{marginTop: "20px", height: "5rem"}}>
                    <input type="checkbox" id="dsgvo"
                           onChange={() => setDsgvo(!dsgvo)}
                           defaultChecked={dsgvo}
                    />
                    {/*eslint-disable-next-line*/}
                    <label htmlFor="dsgvo">{t('general.dsgvo')}. <a onClick={() => setShowPrivacy(true)}>{t('booking.ShowPrivacyData')}</a></label>
                </div>
                <div className="btn" style={{marginTop: "2rem", display: "flex"}} onClick={validateAndGoToOverView}>
                    {t('appointment.goToBookingOverview')} <ChevronsRight />
                </div>

            </div>
        </section>

    );
}

ClientDataEntryForm.defaultProps = {
    name: '',
    setName: null,
    email: '',
    setEmail: null,
    phone: '',
    setPhone: null,
    prefix: '',
    setPrefix: null,
    topic: '',
    setTopic: '',
    selectedAppointmentType: null,
    onSuccess: null
}