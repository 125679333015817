import * as Icon from "react-feather";

export function getButtonIcon(code)
{
    switch (code) {
        case "video":
            return <Icon.Video size={48} color="#FFF" />

        case "chat":
            return <Icon.MessageCircle size={48} color="#FFF" />

        case "person":
        case "inperson":
            return <Icon.Home size={48} color="#FFF" />

        case "phone":
        case "telephone":
            return <Icon.Phone size={48} color="#FFF" />

        case "whatsapp":
            return <div className="platform-icon"><span><img src="/img/logo-whatsapp.png" alt="Logo whatsapp" /></span></div>

        case "msteams":
            return <div className="platform-icon"><span><img src="/img/logo-teams.png" alt="Logo Teams" /></span></div>

        case "signal":
            return <div className="platform-icon"><span><img src="/img/logo-signal.png" alt="Logo Signal" /></span></div>

        default: return null;
    }
}

export function getButtonIconByShortCode(code) {

    let iconSize = 20;
    let iconColor = "#000";

    switch (code) {
        case "V": return <Icon.Video key={code} size={iconSize} color={iconColor} />
        // case "C": return <Icon.MessageCircle key={code} size={iconSize} color={iconColor} />
        case "P": return <Icon.Home key={code} size={iconSize} color={iconColor} />
        case "T": return <Icon.Phone key={code} size={iconSize} color={iconColor} />
        default: return null;
    }
}
