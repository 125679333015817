import {Fragment} from "react";
import Navigation from "./Navigation";
import Footer from "./Footer";


export default function Wrapper({children, hidden, staticData}) {

    if(hidden) return children;

    return(
        <Fragment>
            <Navigation navSiteTitle={staticData ? staticData.siteTitle : ''} />
            <main>
                {children}
            </main>
            <Footer address={staticData ? staticData.footerData : ''} />
        </Fragment>
    )
}

Wrapper.defaultProps = {
    hidden: false,
    siteTitle: null,
    footerData: null
};