export default function TitleRow({title}){
    return (
        <header className="titlerow-header">
            <figure>
                <img className="avatar" src="/img/Sophie.svg" alt="Sophie" />
            </figure>
            <h1>{title}</h1>
        </header>
    )
}

TitleRow.defaultProps = {
    title: ''
}