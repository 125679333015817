import {Fragment} from "react";
import Backdrop from "./Backdrop";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
export default function ErrorMessage({title, message, onClose}) {
    const navigate = useNavigate();
    const [t] = useTranslation('common');

    return(
        <Fragment>
            <div className="error">
                <h2>{title}</h2>
                <p>{message}</p>
                <div className="btn light" style={{ marginRight: '2rem' }} onClick={onClose}>{t('general.close')}</div>
                <div className="btn light" onClick={() => navigate("/desire")}>{t('summary.BacktoHome')}</div>
            </div>
            <Backdrop />
        </Fragment>
    )
}

ErrorMessage.defaultProps = {
    title: '',
    message: '',
    onClose: null
}