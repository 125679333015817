import LocaleButton from "./LocaleButton";

export default function LocaleList({locales, reloadStatic}) {

    return(
        <div className="locale-list">
            {
                locales.map((locale, i) => {
                    return <LocaleButton
                        key={i}
                        locale={locale}
                        reloadStatic={reloadStatic}
                    />
                })
            }
        </div>
    );
}

LocaleList.defaultProps = {
    locale: null
};