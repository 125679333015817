import {getButtonIconByShortCode} from "../utilities/icons";

export default function TimeCard({startTime, endTime, types, onClick}) {

    return (
        <button className="btn btn--timecard" onClick={onClick}>
            <span className="btn--timecard--time">{startTime} - {endTime}</span>
            <span className="btn--timecard--type">
                {
                    types.map((typeKey, i) => {
                        return getButtonIconByShortCode(typeKey)
                    })
                }
            </span>
        </button>
    )
}

TimeCard.defaultProps = {
    startTime: '',
    endTime: '',
    types: [],
    onClick: null
}