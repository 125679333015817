import {createElement, Fragment} from "react";
import parse from 'html-react-parser';

export default function Element({element, loadData}) {

    function getImagePath(path)
    {
        if(!path) return null;
        return process.env.REACT_APP_API_HOST + path;
    }

    const handleOnClickInline = (pageID) => {
        loadData(pageID);
    }

    function getInlineImage(data, i = 0) {
        let imgData = data.split(" ");
        imgData = imgData[0].substring(5, imgData[0].length - 1);

        return <img src={getImagePath(imgData)} width="100%"  alt="" key={i}/>;
    }

    function getContent() {
        let content = '';
        if(element.content) {

            content = parse(element.content, {
                replace: (domNode) => {

                    if(domNode.data && domNode.data.startsWith('[image')) {

                        let data = domNode.data.substring(7, domNode.data.length -1);
                        data = data.split('"][image ');

                        if(data.length > 1) {
                            return createElement('span', null, data.map((img, i) => {
                                return getInlineImage(img, i);
                            }));
                        } else {
                            return getInlineImage(data[0]);
                        }
                    }

                    if(domNode.attribs && domNode.attribs.href) {
                        if(!domNode.attribs.href.startsWith('[')) return;
                        let pageTitle = domNode.childNodes[0].data;

                        let pageID = domNode.attribs.href.substring(1, domNode.attribs.href.length -1);
                        pageID = pageID.split(",");
                        pageID = pageID[pageID.length - 1].split('=')[1];

                        return <span onClick={ () => handleOnClickInline(pageID) } className="link">{ pageTitle }</span>;
                    }
                }
            });
        }

        switch (element.type) {
            case 'HeaderElement':
                return (
                    <div className="HeaderElement__title">
                        <figure>
                            <img className="avatar" src="/img/Sophie.svg" alt="Sophie" />
                        </figure>
                        <h2>{ element.slogan }</h2>
                    </div>
                );
            case 'ImageTextElement':
                return (
                    <Fragment>
                        {
                            getImagePath(element.imagePath) &&
                            <figure>
                                <img src={ getImagePath(element.imagePath) } alt={ element.title } />
                            </figure>
                        }
                        <div>{ content }</div>
                        {
                            element.link &&
                            <a className="btn" href={element.link} target="_blank" rel="noreferrer">
                                { element.linkTitle }
                            </a>
                        }
                    </Fragment>
                )
            case 'ElementContent':
                return content;
            default:
                return '';
        }
    }

    if(!element) return null;

    return (
        <Fragment>
            <section className={`element ${element.type} border-${element.showBorder} ${element.ExtraClass}`}  >                
                { element.showTitle === 1 && <h2>{element.title} </h2> }
                { getContent() }
            </section>
        </Fragment>
    )
}

Element.defaultProps = {
    element: null,
    loadData: null
}
