import {Fragment} from "react";
import ChooseButton from "./ChooseButton";
import {getButtonIcon} from "../utilities/icons";
import {useTranslation} from "react-i18next";

export default function AppointmentTypeList({loadedAppointmentTypes, availableTypes, setSelectedAppointmentType}) {
    const [t] = useTranslation('common');

    function isAvailable(code) {

        switch (code) {
            case "video":
                if(availableTypes.includes("V")) return true;
                break;

            case "chat":
                if(availableTypes.includes("C")) return true;
                break;

            case "person":
            case "inperson":
                if(availableTypes.includes("P")) return true;
                break;

            case "phone":
            case "telephone":
                if(availableTypes.includes("T")) return true;
                break;

            default:
                return false;
        }
        return false;
    }

    const typesToRender = [].concat(loadedAppointmentTypes)
        .sort((a, b) => a.sort > b.sort ? 1 : -1)
        .map((el, i) => {
            return (
                <ChooseButton
                    key={i}
                    title={el.title}
                    content={el.content}
                    onClick={() => setSelectedAppointmentType(el)}
                    icon={getButtonIcon(el.code)}
                    isEnabled={isAvailable(el.code)}
                />
            )
        });

    return(
        <Fragment>
            <h2 className="a-c">{t('appointment.type.choose')}</h2>
            <section className="section-basic section-basic__w-md">
                { typesToRender }
            </section>
        </Fragment>
    )
}

AppointmentTypeList.defaultProps = {
    loadedAppointmentTypes: [],
    availableTypes: [],
    setSelectedAppointmentType: null
}