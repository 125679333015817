export async function loadStaticData(langLong) {
    return fetch(process.env.REACT_APP_API_HOST + '/api/static/' + langLong + '/false/getStaticData/', {
            method: 'GET'
    }).then(response => {
        return response.json();
    }).then((r) => {
        return r;
    }).catch((e) => {
        console.error(e);
    });
}