import LocaleList from "../components/LocaleList";
import {useEffect, useState} from "react";
import {InlineLoader} from "../components/Loading";
import ErrorMessage from "../components/ErrorMessage";

export default function LocalePage({reloadStatic}) {
    const [isLoading, setIsLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [loadedLocales, setLoadedLocales] = useState([]);

    useEffect(() => {
        fetch(
            process.env.REACT_APP_API_HOST + '/api/getLocales'
        ).then(response => {
            return response.json();
        }).then(data => {
            setLoadedLocales(data);
            setIsLoading(false);
        }).catch(e => {
            setIsLoading(false);
            setShowError(true);
        });
    }, [isLoading]);

    function closeErrorMessage() {
        setIsLoading(true);
        setShowError(false);
    }

    if(showError) return <ErrorMessage title="Ups, da ging was schief!" message="Bitte probiers gleich noch einmal!" onClose={() => closeErrorMessage()} />

    return (
        <div className="container l-locale a-c">
            <figure>
                <img className="avatar" src="/img/Sophie-Cover.png" alt="Sophie" />
            </figure>
            <h1>Hallo</h1>
            <p>Bitte wähle deine Sprache</p>
            <section className="section-basic section-basic--orange section-basic--m">
                {
                    isLoading ? <InlineLoader />
                        : <LocaleList locales={loadedLocales} reloadStatic={reloadStatic} />
                }
            </section>
        </div>
    );
}

LocalePage.defaultProps = {}