
export default function ChooseButton({title, content, bgcolor, onClick, icon, isEnabled}) {

    function getStyle() {
        let s = {};

        if(isEnabled) s.backgroundColor = bgcolor;
        else s.cursor = "unset";

        return s;
    }

    function getClassNames() {
        let c = "btn btn--lg";
        if(!isEnabled) c += " disabled";
        return c;
    }

    return(
        <button className={getClassNames()} style={getStyle()} onClick={isEnabled ? onClick : null} >
            <div>
                <span className="btn__head">{title}</span>
                <span className="btn__content">{content}</span>
            </div>
            {icon && icon}
        </button>
    )
}

ChooseButton.defaultProps = {
    title: '',
    content: '',
    bgcolor: '#f68c01',
    isEnabled: true,
    onClick: null,
    icon: null
}
