import {useEffect, useState, Fragment} from "react";
import ConsultantList from "../components/ConsultantList";
import Calender from "../components/Calender";
import Loading from "../components/Loading";
import ClientDataEntryForm from "../components/ClientDataEntryForm";
import ErrorMessage from "../components/ErrorMessage";
import {useTranslation} from "react-i18next";
import BookingOverview from "../components/BookingOverview";
import BookedSummary from "../components/BookedSummary";
import AppointmentTypeList from "../components/AppointmentTypeList";
import SelectedHeader from "../components/SelectedHeader";
import TitleRow from "../components/TitleRow";
import {formatPhoneNumber, getDateStingFormatted, getTimeStingFormatted} from "../utilities/formatters";
import PlatformList from "../components/PlatformList";
import {useCookies} from "react-cookie";

export default function AppointmentPage() {
    const [t] = useTranslation('common');
    const [cookies] = useCookies(['lang']);

    const [isLoading, setIsLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loadedConsultants, setLoadedConsultants] = useState([]);
    const [selectedConsultant, setSelectConsultant] = useState(null);
    const [loadedDates, setLoadedDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [loadedAppointmentTypes, setLoadedAppointmentTypes] = useState(null);
    const [selectedAppointmentType, setSelectedAppointmentType] = useState(null);
    const [selectedPlatform, setSelectedPlatform] = useState(null);
    const [flowState, setFlowState] = useState(0); // 0 Selecting Mode | 1 Overview Mode | 2 Booking was successful

    // Input Data
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [prefix, setPrefix] = useState('+43');
    const [phone, setPhone] = useState('');
    const [topic, setTopic] = useState('');

    useEffect(() => {

        fetch(process.env.REACT_APP_API_HOST + '/api/consultants/' +  cookies.lang.long, {
            method: 'GET'
        }).then(response => {
            return response.json();
        }).then(data => {
            setLoadedConsultants(data.consultants);
            setIsLoading(false);
        }).catch((e) => {
            console.error(e);
            setIsLoading(false);
            setShowError(true);
        });

    }, [cookies.lang.long]);

    function closeErrorMessage() {
        setShowError(false);
        setErrorMessage("");
    }

    if(isLoading) return <Loading />
    if(showError) return <ErrorMessage title="{t('error')}" message={errorMessage} onClose={() => closeErrorMessage()} />

    async function selectConsultant(e, consultant) {
        setIsLoading(true);
        setSelectConsultant(consultant);
        setLoadedDates(consultant.dates);
        setIsLoading(false);
    }

    async function resetConsultant() {
        setFlowState(0)
        await setSelectConsultant(null);
        await setLoadedDates([]);
        await setSelectedDate(null);
        await setSelectedTime(null);
        await setSelectedAppointmentType(null);
    }

    function onSelectDate(date) {
        setSelectedDate(date);
    }

    async function onSelectTime(e, time) {
        setSelectedTime(time);
        await fetchAppointmentTypes();
    }

    async function fetchAppointmentTypes() {
        if(!loadedAppointmentTypes) {
            setIsLoading(true);

            fetch(process.env.REACT_APP_API_HOST + '/api/appointment/' + cookies.lang.long + '/getTypes', {
                method: 'GET'
            }).then(response => {
                return response.json();
            }).then(data => {
                setLoadedAppointmentTypes(data);
                setIsLoading(false);
            }).catch((e) => {
                setLoadedAppointmentTypes([])
                setIsLoading(false);
            });
        }
    }

    async function resetDateAndTime() {
        setFlowState(0)
        await setSelectedDate(null);
        await setSelectedTime(null);
        await resetAppointmentType();
        await resetPlatform();
    }

    async function resetAppointmentType() {
        setFlowState(0)
        await resetPlatform();
        await setSelectedAppointmentType(null);
    }

    async function resetPlatform() {
        setFlowState(0)
        await setSelectedPlatform(null);
    }

    function goToBookingOverview() {
        setFlowState(1);

        let updatedPhone = formatPhoneNumber(phone);
        setPhone(updatedPhone);
    }

    function currentViewState() {
        if(selectedConsultant === null ) return 'consultantList';
        if((selectedDate === null) || (selectedTime === null)) return 'calender';
        if(selectedAppointmentType === null) return 'appointmentTypeList';
        if(selectedAppointmentType.platforms.length > 0 && selectedPlatform === null) return 'platformList';
        return 'clientDataEntryForm'
    }

    if(flowState === 0) {
        return (
            <Fragment>
                {
                    currentViewState() === 'consultantList' ?
                        <Fragment>
                            <TitleRow title={t('appointment.consultantTitle')} />
                            <ConsultantList
                                consultants={loadedConsultants}
                                selectConsultant={selectConsultant}
                            />
                        </Fragment>
                        : <SelectedHeader
                            consultant={selectedConsultant}
                            resetConsultant={resetConsultant}
                            selectedDate={getDateStingFormatted(selectedDate)}
                            selectedTime={getTimeStingFormatted(selectedTime)}
                            resetDateAndTime={resetDateAndTime}
                            selectedAppointmentType={selectedAppointmentType}
                            resetAppointmentType={resetAppointmentType}
                            selectedPlatform={selectedPlatform}
                            resetPlatform={resetPlatform}
                        />
                }
                {
                    currentViewState() === 'calender' &&
                        <Calender
                            dates={loadedDates}
                            onSelectDate={(date) => onSelectDate(date)}
                            onSelectTime={(e, time) => onSelectTime(e, time)}
                            resetConsultant={resetConsultant}
                        />
                }
                {
                    currentViewState() === 'appointmentTypeList' &&
                        <AppointmentTypeList
                            setSelectedAppointmentType={setSelectedAppointmentType}
                            loadedAppointmentTypes={loadedAppointmentTypes}
                            availableTypes={selectedTime.types}
                        />
                }
                {
                    currentViewState() === 'platformList' &&
                    <PlatformList
                        platforms={selectedAppointmentType.platforms}
                        setSelectedPlatform={setSelectedPlatform}
                    />
                }
                {
                    currentViewState() === 'clientDataEntryForm' &&
                        <ClientDataEntryForm
                            name={name}
                            setName={setName}
                            email={email}
                            setEmail={setEmail}
                            prefix={prefix}
                            setPrefix={setPrefix}
                            topic={topic}
                            setTopic={setTopic}
                            phone={phone}
                            setPhone={setPhone}
                            onSuccess={goToBookingOverview}
                            selectedAppointmentType={selectedAppointmentType}
                        />
                }
            </Fragment>
        );
    }

    // Booking Overview
    if(flowState === 1) {
        return (
            <BookingOverview
                selectedDate={getDateStingFormatted(selectedDate)}
                selectedTime={getTimeStingFormatted(selectedTime)}
                resetDateAndTime={resetDateAndTime}
                selectedAppointment={selectedTime}
                selectedConsultant={selectedConsultant}
                resetConsultant={resetConsultant}
                selectedAppointmentType={selectedAppointmentType}
                resetAppointmentType={resetAppointmentType}
                selectedPlatform={selectedPlatform}
                resetPlatform={resetPlatform}
                givenName={name}
                givenEmail={email}
                givenPrefix={prefix}
                givenPhone={phone}
                givenTopic={topic}
                changeData={() => setFlowState(0)}
                onSuccess={() => setFlowState(2)}
            />
        )
    }

    // Booking was successfully
    if(flowState === 2) {
        return (
            <BookedSummary
                givenName={name}
                givenEmail={email}
                givenPhone={phone}
                givenTopic={topic}
                selectedDate={getDateStingFormatted(selectedDate)}
                selectedTime={getTimeStingFormatted(selectedTime)}
                selectedConsultantName={selectedConsultant.name}
                selectedAppointmentTypeTitle={selectedAppointmentType.title}
            />
        )
    }
}

AppointmentPage.defaultProps = {}
