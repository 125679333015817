import {PuffLoader} from "react-spinners";
import Backdrop from "./Backdrop";
import style from "./Loading.module.scss";
import {Fragment} from "react";

export default function Loader() {

    return(
        <Fragment>
            <div className={style.spinner}>
                <InlineLoader />
            </div>
            <Backdrop />
        </Fragment>
    )
}

export function InlineLoader() {
    return <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        <PuffLoader color={'#A5002D'} loading={true} size={40} />
    </div>
}