import {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";
import Loading from "./Loading";
import ErrorMessage from "./ErrorMessage";
import TitleRow from "./TitleRow";
import BookingOverviewItem from "./BookingOverviewItem";
import {useCookies} from "react-cookie";

export default function BookingOverview({givenName, givenPrefix, givenPhone, givenTopic, givenEmail,
                                         selectedPlatform, selectedDate, selectedAppointment, selectedConsultant, selectedAppointmentType,
                                         onSuccess, resetConsultant, selectedTime, resetDateAndTime, resetAppointmentType, changeData
}) {

    const [t] = useTranslation('common');
    const [cookies] = useCookies(['lang']);

    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    async function bookAppointment() {
        setIsLoading(true);

        let bookedTimeSlot = true;

        if(bookedTimeSlot) {
            let platform = "";
            if(selectedPlatform !== null) platform = selectedPlatform.id;

            let body = {
                Name: givenName,
                Phone: givenPrefix + givenPhone,
                Email: givenEmail,
                Topic: givenTopic,
                Date: selectedDate,
                Time: selectedAppointment.startTime,
                OutlookID: selectedAppointment.id,
                ConsultantID: selectedConsultant.id,
                TypeID: selectedAppointmentType.id,
                PlatformID: platform
            };

            await fetch(process.env.REACT_APP_API_HOST + '/api/appointment/' + cookies.lang.long + '/book', {
                    method: 'POST', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    body: JSON.stringify(body),
            }).then(response => {
                if(response.status === 200) onSuccess();
                if(response.status === 409) {
                    setErrorMessage('Termin wurde gebucht - aber beim speichern ging etwas schief');
                    setShowError(true);
                }
                if(response.status === 423) {
                    setErrorMessage(response.statusMessage)
                    setShowError(true);
                }
                else {
                    setErrorMessage("Beim Buchen ging etwas schief, bitte wähle einen anderen Termin oder versuche es später noch einmal.")
                    setShowError(true);
                }
                setIsLoading(false);
            }).catch((e) => {
                setErrorMessage("Beim Buchen ging etwas schief, bitte wähle einen anderen Termin!")
                setShowError(true);
                setIsLoading(false);
            });
        } else {
            setErrorMessage("3 Beim Buchen ging etwas schief, bitte wähle einen anderen Termin!")
            setShowError(true);
        }
    }

    if(isLoading) return <Loading withoutWrapper />
    if(showError) return <ErrorMessage title={t('error.title')} message={errorMessage} onClose={() => setShowError(false)} />

    return(
        <Fragment>
            <TitleRow title={t('booking.titleCheckData')} />

            <section className="section-basic section-basic__w-md">
            <div className="summary-card">

                <BookingOverviewItem title={t('booking.YourContact')} onEdit={resetConsultant} >
                    {selectedConsultant.name}
                </BookingOverviewItem>

                <BookingOverviewItem title={t('booking.Date')} onEdit={resetDateAndTime} spaceTop >
                    <span>{selectedDate}<br />{selectedTime}</span>
                </BookingOverviewItem>

                <BookingOverviewItem title={t('booking.DateType')} onEdit={resetAppointmentType} spaceTop >
                    <span>
                        { selectedAppointmentType.title }
                        { selectedPlatform ? <span> ({selectedPlatform.title})</span> : null }
                    </span>
                </BookingOverviewItem>

                <BookingOverviewItem title={t('booking.YourData')} onEdit={changeData} spaceTop >
                        <span>
                            {givenName}
                            {givenEmail && <Fragment><br />{givenEmail}</Fragment>}
                            {givenPhone && <Fragment><br />{givenPrefix + ' ' + givenPhone}</Fragment>}
                        </span>
                </BookingOverviewItem>

                {
                    givenTopic &&
                    <BookingOverviewItem title={t('general.topic')} onEdit={changeData} spaceTop >
                        <span>
                            {givenTopic}
                        </span>
                    </BookingOverviewItem>
                }

            </div>

            <div className="summary-card summary-card--bright">
                <p>{t('booking.PreBookingMessage')}</p>
                <div className="btn" style={{marginTop: "2rem", display: "flex"}} onClick={async () => await bookAppointment()}>{t('booking.ConfirmBooking')}</div>
            </div>
            </section>
        </Fragment>
    )
}

BookingOverview.defaultProps = {
    givenName: '',
    givenPrefix: '',
    givenPhone: '',
    givenEmail: '',
    givenTopic: '',
    selectedDate: '',
    selectedPlatform: null,
    selectedAppointment: null,
    selectedAppointmentType: null,
    selectedConsultant: null,
    onSuccess: null,
    resetConsultant: null,
    selectedTime: '',
    resetDateAndTime: null,
    resetAppointmentType: null,
    changeData: null
}