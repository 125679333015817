import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {HashRouter} from "react-router-dom";
import i18next from "i18next";
import {I18nextProvider} from "react-i18next";
import common_bg from "./translations/bg/common.json";
import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";
import common_es from "./translations/es/common.json";
import common_hu from "./translations/hu/common.json";
import common_ro from "./translations/ro/common.json";
import common_ru from "./translations/ru/common.json";


const root = ReactDOM.createRoot(document.getElementById('root'));

// https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-react-app-with-react-i18next
i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
        // 'common' is our custom namespace
        bg: { common: common_bg },
        de: { common: common_de },
        en: { common: common_en },
        es: { common: common_es },
        hu: { common: common_hu },
        ro: { common: common_ro },
        ru: { common: common_ru },
    },
});

root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <HashRouter>
                <App />
            </HashRouter>
        </I18nextProvider>
    </React.StrictMode>
);