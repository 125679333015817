import {Edit3} from "react-feather";

export default function SelectedHeader({consultant, resetConsultant, selectedAppointmentType, resetAppointmentType, selectedPlatform, resetPlatform, selectedDate, selectedTime, resetDateAndTime}) {

    function getImagePath()
    {
        if(consultant.picture !== null) {
            return process.env.REACT_APP_API_HOST + consultant.picture;
        }
        return '/logo192.png';
    }


    return(
        <div className="selected-header">
            <div className="nameHolder">
                <img className="center-vertical" style={{objectFit: "cover"}} src={getImagePath()} alt={consultant.name} width={50} height={50}/>
                <HeaderItem item={consultant} resetItem={resetConsultant} >
                    <span className="name">{consultant.name}</span>
                </HeaderItem>
            </div>
            <div className="selected-header__data">
                <HeaderItem item={selectedDate} resetItem={resetDateAndTime} displayReset={selectedTime} >
                    <span className="dings">{selectedDate}</span>{ selectedTime !== null && <span className="dings">{selectedTime}</span> }
                </HeaderItem>
            </div>

            {
                selectedAppointmentType &&
                <HeaderItem item={selectedAppointmentType} resetItem={resetAppointmentType} displayReset={selectedPlatform} >
                    <span>{selectedAppointmentType.title} { selectedPlatform !== null && <span>({selectedPlatform.title})</span>}</span>
                </HeaderItem>
            }


            {/*{*/}
            {/*    props.selectedVideoType !== null &&*/}
            {/*    <div className="center-vertical" style={{display: "flex", gap: "0.5rem"}}>*/}
            {/*        <span>{props.selectedVideoType.title}</span>*/}
            {/*        <Edit3 className="center-vertical" style={{cursor: "pointer"}} onClick={props.resetPlatform} color="#a5002d" />*/}
            {/*    </div>*/}
            {/*}*/}

        </div>
    )
}

SelectedHeader.defaultProps = {
    consultant: null,
    resetConsultant: null,
    selectedAppointmentType: null,
    resetAppointmentType: null,
    selectedPlatform: null,
    resetPlatform: null,
    selectedDate: null,
    selectedTime: null,
    resetDateAndTime: null
}

function HeaderItem({item, resetItem, displayReset, children}) {
    if(!item) return null;

    return (
        <div className="selected-header__data va-c">
            { children }
            { displayReset && <Edit3 className="center-vertical" style={{cursor: "pointer"}} onClick={resetItem} color="#a5002d" /> }
        </div>
    )
}

HeaderItem.defaultProps = {
    item: null,
    resetItem: null,
    displayReset: true
}