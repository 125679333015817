import ConsultantsCard from "./ConsultantsCard";


export default function ConsultantList({consultants, selectConsultant}) {
    return(
        <section className="section-basic section-basic__w-md">
            <div className="consultant-list">
            {
                consultants.map((consultant, i) => {
                    return <ConsultantsCard
                        key={i}
                        consultant={consultant}
                        onClick={(e) => selectConsultant(e, consultant)}
                    />
                })
            }
            </div>
        </section>
    );
}

ConsultantList.defaultProps = {
    consultants: [],
    selectConsultant: null
}