import {useNavigate} from "react-router-dom";
import {useRef} from "react";
import {useTranslation} from "react-i18next";

export default function Navigation({navSiteTitle = ''}) {
    const [t] = useTranslation('common');
    const navigate = useNavigate();
    const navRef = useRef();

    function handleNavClick() {
        navRef.current.querySelector('button').classList.toggle('menu-toggle--open');
        navRef.current.querySelector('.nav-container').classList.toggle('nav-container--open')
    }

    function navigateTo(route) {
        handleNavClick();
        navigate(route);
    }

    return(
        <nav ref={navRef}>
            <div style={{marginTop: "auto", marginBottom: "auto", cursor: "pointer"}} onClick={() => navigateTo("/desire")}>
                <img className="logo" src="/img/logo.png" alt="Logo Sophie" />
            </div>
            <div className="nav-siteinfo">{navSiteTitle}</div>
            <button className="menu-toggle" onClick={() => handleNavClick()} aria-label="Open main menu">
                <i></i>
            </button>
            
            <div className="nav-container">
                <ul className="nav-content">
                    <li className="first" onClick={() => navigateTo("/")}>{t('pagenavigation.changelanguage')}</li>
                    <li onClick={() => navigateTo("/infos")}>{t('pagenavigation.Information')}</li>
                    <li onClick={() => navigateTo("/imprint")}>{t('pagenavigation.Imprint')}</li>
                    <li onClick={() => navigateTo("/dataprivacy")}>{t('pagenavigation.PrivacyPolicy')}</li>
                </ul>
            </div>
        </nav>
    )
}