import Flag from 'react-flagkit';
import {useTranslation} from "react-i18next";
export default function ConsultantsCard({consultant, onClick}) {
    const [t] = useTranslation('common');
    function getImagePath() {
        if(consultant.picture !== null) {
            return process.env.REACT_APP_API_HOST + consultant.picture;
        }
        return '/logo192.png';
    }

    function getOnClickFunction() {
        if(consultant.dates.length === 0) {
            return null;
        }
        return onClick;
    }

    function getClassNames(){
        if(consultant.dates.length === 0) {
            return "consultantCard";
        }
        return "consultantCard active";
    }

    if(!consultant) return null;

    return(
        <div className={getClassNames()} onClick={getOnClickFunction()}>
            <div className="consultantCard-content">
                <h3>{consultant.name}</h3>

                {
                    consultant.dates.length === 0 &&
                        <div className="available-dates available-dates__empty">{t('appointment.noConsultantDates')}</div>
                }
                <div className="consultant--lang">
                    {
                        consultant.spokenLanguages.map((lang, i) => {
                            return <Flag key={i} country={lang.code} size={20} />;
                        })
                    }
                </div>
            </div>
            <div className="consultantImgHolder">
                <img src={getImagePath()} alt={consultant.name} width={100} height={100}/>
            </div>
        </div>
    )
}

ConsultantsCard.defaultProps = {
    consultant: null,
    onClick: null
}
