export function getDateStingFormatted(date) {
    if(date) {
        return date.toLocaleDateString("de-AT", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
    }
}

export function getTimeStingFormatted(time) {
    if(time) return time.startTime + 'h - ' + time.endTime + 'h';
}

export function formatPhoneNumber(number) {
    number = number.replace(' ', '');
    number = number.replace('/', '');
    number = number.replace('-', '');

    if(number.charAt(0) === '0') number = number.substring(1, number.length);

    return number;
}