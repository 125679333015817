import {Fragment, useEffect, useState} from "react";
import Loading from "./Loading";
import {useCookies} from "react-cookie";

export default function GenericPageContent({isPreview, type}) {
    const [cookies] = useCookies(['lang']);

    const [isLoading, setIsLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [previewLoaded, setPreviewLoaded] = useState(false);

    const [data, setData] = useState();

    useEffect(() => {
        // Loads data on access and on type change
        loadData().then(r => { return r });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    if(isPreview && !previewLoaded) {
        loadData().then((r) => setPreviewLoaded(true));
    }

    async function loadData() {
        await setIsLoading(true);

        await fetch(
            process.env.REACT_APP_API_HOST + '/api/static/' + cookies.lang.long + '/' + isPreview.toString() + '/getGenericPage/' + type, {
                method: 'GET'
            }
        ).then(async response => {
            let data = await response.json();
            await setData(data);
            await setIsLoading(false);
        }).catch((e) => {
            setShowError(true);
            setIsLoading(false);
        });
    }

    if(isLoading) return <Loading withoutWrapper />;
    if(showError) return (
        <Fragment>
            <h1>Error</h1>
            <div style={{marginTop: '2rem', color: "red"}}>Something went wrong!</div>
        </Fragment>
    )

    return (
        <Fragment>
            <h1>{data.title}</h1>
            <div style={{marginTop: '2rem'}} dangerouslySetInnerHTML={{__html: data.content}} />
        </Fragment>

    )
}

GenericPageContent.defaultProps = {
    isPreview: 'false',
    type: ''
}