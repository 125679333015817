import {useLocation} from "react-router-dom";
import GenericPageContent from "../components/GenericPageContent";

export default function GenericPage({type}) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    // Get Data for PREVIEW
    const isPreview = !!searchParams.get('isPreview');

    return(
        <div className="page section-basic__w-md">
            <GenericPageContent
                isPreview={isPreview}
                type={type}
            />
        </div>
    )
}

GenericPage.defaultProps = {
    type: ''
}